// <!-- API -->
import { defineAsyncComponent } from 'vue';

// <!-- COMPONENTS -->
import LoadingFormStep from '~CSVUploader/components/steps/LoadingFormStep.vue';
import MissingFormStep from '~CSVUploader/components/steps/MissingFormStep.vue';

// <!-- SETTINGS -->
const ASYNC_DELAY = 200;
const ASYNC_TIMEOUT = 3000;

// <!-- MAP -->
const AsyncComponentRecord = {
    GenericFormStep: () =>
        defineAsyncComponent({
            loader: () =>
                import('~CSVUploader/components/steps/GenericFormStep.vue'),
            // ts-ignore
            loadingComponent: /** @type {V.Component} */ (LoadingFormStep),
            delay: ASYNC_DELAY,
            // ts-ignore
            errorComponent: /** @type {V.Component} */ (MissingFormStep),
            timeout: ASYNC_TIMEOUT,
        }),
    FileSelectorStep: () =>
        defineAsyncComponent({
            loader: () =>
                import('~CSVUploader/components/steps/FileSelectorStep.vue'),
            // ts-ignore
            loadingComponent: /** @type {V.Component} */ (LoadingFormStep),
            delay: ASYNC_DELAY,
            // ts-ignore
            errorComponent: /** @type {V.Component} */ (MissingFormStep),
            timeout: ASYNC_TIMEOUT,
        }),
    LocationSelectorStep: () =>
        defineAsyncComponent({
            loader: () =>
                import(
                    '~CSVUploader/components/steps/LocationSelectorStep.vue'
                ),
            // ts-ignore
            loadingComponent: /** @type {V.Component} */ (LoadingFormStep),
            delay: ASYNC_DELAY,
            // ts-ignore
            errorComponent: /** @type {V.Component} */ (MissingFormStep),
            timeout: ASYNC_TIMEOUT,
        }),
    ProfileSelectorStep: () =>
        defineAsyncComponent({
            loader: () =>
                import('~CSVUploader/components/steps/ProfileSelectorStep.vue'),
            // ts-ignore
            loadingComponent: /** @type {V.Component} */ (LoadingFormStep),
            delay: ASYNC_DELAY,
            // ts-ignore
            errorComponent: /** @type {V.Component} */ (MissingFormStep),
            timeout: ASYNC_TIMEOUT,
        }),
    UploadSummaryStep: () =>
        defineAsyncComponent({
            loader: () =>
                import('~CSVUploader/components/steps/UploadSummaryStep.vue'),
            // ts-ignore
            loadingComponent: /** @type {V.Component} */ (LoadingFormStep),
            delay: ASYNC_DELAY,
            // ts-ignore
            errorComponent: /** @type {V.Component} */ (MissingFormStep),
            timeout: ASYNC_TIMEOUT,
        }),
};

/**
 * Use a specific async component.
 * @param {keyof AsyncComponentRecord} id
 */
export const useStepComponent = (id) => {
    return id !== undefined ? AsyncComponentRecord[id]() : null;
};

const AsyncModalRecord = {
    SelectProfileModal: () =>
        defineAsyncComponent({
            loader: () =>
                import('~CSVUploader/components/modals/SelectProfileModal.vue'),
            // ts-ignore
            loadingComponent: /** @type {V.Component} */ (LoadingFormStep),
            delay: ASYNC_DELAY,
            // ts-ignore
            errorComponent: /** @type {V.Component} */ (MissingFormStep),
            timeout: ASYNC_TIMEOUT,
        }),
    PreviewPEMModal: () =>
        defineAsyncComponent({
            loader: () =>
                import('~CSVUploader/components/modals/PreviewPEMModal.vue'),
            // ts-ignore
            loadingComponent: /** @type {V.Component} */ (LoadingFormStep),
            delay: ASYNC_DELAY,
            // ts-ignore
            errorComponent: /** @type {V.Component} */ (MissingFormStep),
            timeout: ASYNC_TIMEOUT,
        }),
};

/**
 * Use a specific async component.
 * @param {keyof AsyncModalRecord} id
 */
export const useStepModal = (id) => {
    return id !== undefined ? AsyncModalRecord[id]() : null;
};

// DEFAULT
export default useStepComponent;
