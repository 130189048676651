<template>
    <router-view v-slot="{ Component }">
        <!-- <keep-alive> -->
        <component :is="Component" />
        <!-- </keep-alive> -->
    </router-view>
</template>

<script>
    // <!-- API -->
    import { defineComponent, onBeforeMount } from 'vue';

    // <!-- COMPOSABLES -->
    import { useRouter } from 'vue-router';
    import { useExpiredSubscriptionGate } from '@/hooks/gates';
    import { useNARAFeature } from '@/utils/features/';

    // <!-- DEFINITION -->
    export default defineComponent({
        name: 'UploaderIndex',
        setup(props) {
            // Get the router.
            const router = useRouter();

            // Expiration guards.
            const { isSuperUser, isCurrentSubscriptionExpired } =
                useExpiredSubscriptionGate();
            const { isNARADisabled } = useNARAFeature();

            // Regsiter lifecycle.
            onBeforeMount(async () => {
                // Check if the user subscription is expired.
                if (
                    !isSuperUser.value &&
                    isCurrentSubscriptionExpired.value &&
                    isNARADisabled.value
                ) {
                    return await router.push('/expired-subscription');
                }
            });
        },
    });
</script>
