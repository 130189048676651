// <!-- API -->
import { ref, computed } from 'vue';
import { Assertion } from '@/store/types/ECNBAssertions';
import { Emoji } from '@/utils/emoji';

/**
 * @class
 * Form navigation policies control the label and button action.
 */
export class FormNavigationPolicy {
    /**
     * Create a form navigation policy with explicit parameters.
     */
    static prepare(initial = {}) {
        /**
         * Internal policy builder class.
         */
        class PolicyBuilder {
            /**
             * Create policy builder instance.
             * @param {Partial<Pick<FormNavigationPolicy, 'type' | 'action' | 'labelRef' | 'showRef' | 'enableRef'>>} initial;
             */
            constructor(initial) {
                /** @type {Partial<Pick<FormNavigationPolicy, 'type' | 'action' | 'labelRef' | 'showRef' | 'enableRef'>>} */
                this.attributes = Object.assign({}, initial);

                // Extend the setType function.
            }

            /**
             * Create a policy instance.
             */
            create() {
                return new FormNavigationPolicy(this.attributes);
            }

            /**
             * Get access to the type mutator.
             */
            get type() {
                // Reference to builder.
                const $context = this;

                /**
                 * Set the policy type.
                 * @param {FormNavigationPolicy['type']} type
                 */
                function set(type) {
                    $context.attributes.type = type;
                    return $context;
                }

                /**
                 * Set to the submit type.
                 */
                function asSubmit() {
                    return set('submit');
                }

                function asReset() {
                    return set('reset');
                }

                return {
                    value: this.attributes?.type,
                    set,
                    asSubmit,
                    asReset,
                };
            }

            /**
             * Get access to the label mutator.
             */
            get label() {
                // Reference to builder.
                const $context = this;

                /**
                 * Set the policy label.
                 * @param {FormNavigationPolicy['labelRef']} value
                 */
                function set(value) {
                    $context.attributes.labelRef = value;
                    return $context;
                }

                /**
                 * Set policy label to constant string.
                 * @param {String} value
                 */
                function exact(value) {
                    const label = computed(() => value);
                    return set(label);
                }

                /**
                 * Set the policy label.
                 * @param {V.Ref<String>} value
                 */
                function withRef(value) {
                    return set(value);
                }

                /**
                 * Set the policy label.
                 * @param {V.ComputedRef<String>} value
                 */
                function withComputed(value) {
                    return set(value);
                }

                /**
                 * Set the policy label.
                 * @param {() => String} supplier
                 */
                function from(supplier) {
                    return withComputed(computed(supplier));
                }

                /**
                 * Set policy property to the default value.
                 */
                function useDefault() {
                    return exact('Label');
                }

                return {
                    value: this.attributes?.labelRef?.value,
                    set,
                    exact,
                    from,
                    withRef,
                    withComputed,
                    useDefault,
                };
            }

            /**
             * Get access to the policy visiblity.
             */
            get visible() {
                // Reference to builder.
                const $context = this;

                /**
                 * Set the policy property.
                 * @param {FormNavigationPolicy['showRef']} value
                 */
                function set(value) {
                    $context.attributes.showRef = value;
                    return $context;
                }

                /**
                 * Set policy property to constant.
                 * @param {Boolean} value
                 */
                function exact(value) {
                    const prop = computed(() => value);
                    return set(prop);
                }

                /**
                 * Set the policy property.
                 * @param {V.Ref<Boolean>} value
                 */
                function withRef(value) {
                    return set(value);
                }

                /**
                 * Set the policy property.
                 * @param {V.ComputedRef<Boolean>} value
                 */
                function withComputed(value) {
                    return set(value);
                }

                /**
                 * Set the policy property.
                 * @param {() => Boolean} supplier
                 */
                function from(supplier) {
                    return withComputed(computed(supplier));
                }

                /**
                 * Set to true.
                 */
                function on() {
                    return exact(true);
                }

                /**
                 * Set to false.
                 */
                function off() {
                    return exact(false);
                }

                /**
                 * Use `true` when supplier is true.
                 * @param {() => Boolean} supplier
                 */
                function whenTrue(supplier) {
                    const computation = computed(supplier);
                    return from(() => computation.value === true);
                }

                /**
                 * Use `true` when supplier is false.
                 * @param {() => Boolean} supplier
                 */
                function whenFalse(supplier) {
                    const computation = computed(supplier);
                    return from(() => computation.value === false);
                }

                /**
                 * Set policy property to the default value.
                 */
                function useDefault() {
                    return on();
                }

                return {
                    value: this.attributes?.showRef?.value,
                    set,
                    exact,
                    from,
                    withRef,
                    withComputed,
                    on,
                    off,
                    show: on,
                    hide: off,
                    whenTrue,
                    whenFalse,
                    useDefault,
                };
            }

            /**
             * Get access to the policy enabled status.
             */
            get enabled() {
                // Reference to builder.
                const $context = this;

                /**
                 * Set the policy property.
                 * @param {FormNavigationPolicy['enableRef']} value
                 */
                function set(value) {
                    $context.attributes.enableRef = value;
                    return $context;
                }

                /**
                 * Set policy property to constant.
                 * @param {Boolean} value
                 */
                function exact(value) {
                    const prop = computed(() => value);
                    return set(prop);
                }

                /**
                 * Set the policy property.
                 * @param {V.Ref<Boolean>} value
                 */
                function withRef(value) {
                    return set(value);
                }

                /**
                 * Set the policy property.
                 * @param {V.ComputedRef<Boolean>} value
                 */
                function withComputed(value) {
                    return set(value);
                }

                /**
                 * Set the policy property.
                 * @param {() => Boolean} supplier
                 */
                function from(supplier) {
                    return withComputed(computed(supplier));
                }

                /**
                 * Use `true` when supplier is true.
                 * @param {() => Boolean} supplier
                 */
                function whenTrue(supplier) {
                    const computation = computed(supplier);
                    return from(() => computation.value === true);
                }

                /**
                 * Use `true` when supplier is false.
                 * @param {() => Boolean} supplier
                 */
                function whenFalse(supplier) {
                    const computation = computed(supplier);
                    return from(() => computation.value === false);
                }

                /**
                 * Set to true.
                 */
                function on() {
                    return exact(true);
                }

                /**
                 * Set to false.
                 */
                function off() {
                    return exact(false);
                }

                /**
                 * Set policy property to the default value.
                 */
                function useDefault() {
                    return on();
                }

                return {
                    value: this.attributes?.enableRef?.value,
                    set,
                    exact,
                    from,
                    withRef,
                    withComputed,
                    on,
                    off,
                    enabled: on,
                    disabled: off,
                    whenTrue,
                    whenFalse,
                    useDefault,
                };
            }

            /**
             * Get access to the policy action.
             */
            get action() {
                // Reference to builder.
                const $context = this;

                /**
                 * Set the policy property.
                 * @param {FormNavigationPolicy['action']} value
                 */
                function set(value) {
                    $context.attributes.action = value;
                    return $context;
                }

                /**
                 * Set the policy property.
                 * @param {FormNavigationPolicy['action']} value
                 */
                function onClick(value) {
                    return set(value);
                }

                /**
                 * Set the policy property.
                 * @param {FormNavigationPolicy['action']} value
                 */
                function withCallback(value) {
                    return set(value);
                }

                /**
                 * Set the policy property.
                 * @param {Promise<Boolean> | (() => Promise<Boolean>)} value
                 */
                function withAsyncCallback(value) {
                    return set(value);
                }

                /**
                 * Set policy property to the default value.
                 */
                function useDefault() {
                    return set(() => false);
                }

                return {
                    value: this.attributes?.action,
                    set,
                    onClick,
                    withCallback,
                    withAsyncCallback,
                    useDefault,
                };
            }
        }

        // Return the builder.
        return new PolicyBuilder(initial);
    }

    /**
     * Invoke synchronous or asynchronous action and return the result.
     * @param {Promise<Boolean|void> | (() => Boolean|void) | (() => Promise<Boolean|void>)} action Synchronous or asynchronous callback.
     * @param {Boolean|null} defaultValue Default value to return when action is not provided.
     * @returns {Promise<Boolean|void>}
     */
    static async invokeAsync(action, defaultValue = false) {
        if (!!action) {
            if (typeof action === 'function') {
                const result = action();
                if (
                    typeof result === 'object' &&
                    typeof result.then === 'function'
                ) {
                    return await result;
                }
                return result;
            }
            // Await and return potential promise.
            return await action;
        }
        return defaultValue;
    }

    /**
     * Individual form navigation policies.
     * @param {Partial<FormNavigationPolicy>} [attributes]
     */
    constructor(attributes = {}) {
        /** @type {"submit" | "reset"} Unique policy identifier. */
        this.type = 'submit';
        /** @type {V.Ref<String> | V.ComputedRef<String>} Determines label text for navigation UI associated with this policy. */
        this.labelRef = ref('');
        /** @type {V.Ref<Boolean> | V.ComputedRef<Boolean>} Determines if the navigation UI associated with this policy is visible. */
        this.showRef = ref(true);
        /** @type {V.Ref<Boolean> | V.ComputedRef<Boolean>} Determines if the navigation UI associated with this policy is clickable. */
        this.enableRef = ref(true);
        /** @type {(Promise<Boolean|void>) | (() => Boolean|void) | (() => Promise<Boolean|void>)} */
        this.action = () => {};
        // Seal the policy to prevent removal or addition of properties.
        Object.seal(this);
        // Copy over default values.
        Object.assign(this, attributes);
    }

    get isSubmissionPolicy() {
        return this.type === 'submit';
    }

    get isResetPolicy() {
        return this.type === 'reset';
    }

    get label() {
        return this.labelRef;
    }

    get isVisible() {
        return this.showRef;
    }

    get isEnabled() {
        return this.enableRef;
    }

    async onClick() {
        try {
            console.groupCollapsed(`[${this.type}::click]`);
            await Assertion.expect(this.isEnabled.value).isTruthy({
                success: `[${[this.label.value]}] is enabled.`,
                failure: `[${[this.label.value]}] is not enabled.`,
            });
            await Assertion.expect(this.isVisible).isTruthy({
                success: `[${[this.label.value]}] is visible.`,
                failure: `[${[this.label.value]}] is not visible.`,
            });
            console.log(
                `[${this.type}::click] - ${Emoji.checkmark} %cSuccess!`,
                'color:seagreen;'
            );
        } catch (error) {
            console.error(error);
            console.log(
                `[${this.type}::click] - ${Emoji.cross} %cFailure!`,
                'color:firebrick;'
            );
        } finally {
            console.groupEnd();
        }
        return await FormNavigationPolicy.invokeAsync(this.action);
    }
}

/**
 * @class
 * Form navigation policy handler that provides a map of navigation policy types.
 */
export class FormNavigationPolicyHandler {
    /**
     * Get the default policy.
     * @param {'submit' | 'reset'} type
     */
    static getDefaultPolicy(type) {
        return FormNavigationPolicy.prepare()
            .type.set(type)
            .label.exact(type === 'submit' ? 'Next' : 'Back')
            .action.useDefault()
            .visible.useDefault()
            .enabled.useDefault()
            .create();
    }

    /**
     * Create a form navigation policy handler.
     * @param {Partial<{ submit: FormNavigationPolicy, reset: FormNavigationPolicy }>} initial
     */
    static prepare(initial = {}) {
        /** @type {Partial<{ submit: FormNavigationPolicy, reset: FormNavigationPolicy }>} */
        const attributes = Object.assign({}, initial);

        // <!-- CREATE -->
        /**
         * Create the new policy. Terminates the factory.
         */
        const create = () => {
            return new FormNavigationPolicyHandler(
                new Map([
                    ['submit', attributes.submit],
                    ['reset', attributes.reset],
                ])
            );
        };

        // <!-- SET SUBMIT -->
        /**
         * Set the initial policy.
         * @param {FormNavigationPolicy} policy
         */
        const setPolicy = (policy) => {
            attributes[policy.type] = policy;
            return context;
        };
        setPolicy.to = setPolicy;

        /**
         * Set the submit policy.
         * @param {FormNavigationPolicy} policy
         */
        const setSubmit = (policy) => {
            const submit =
                policy ??
                FormNavigationPolicyHandler.getDefaultPolicy('submit');
            submit.type = 'submit';
            return setPolicy(submit);
        };

        /**
         * Set the reset policy.
         * @param {FormNavigationPolicy} policy
         */
        const setReset = (policy) => {
            const reset =
                policy ?? FormNavigationPolicyHandler.getDefaultPolicy('reset');
            reset.type = 'reset';
            return setPolicy(reset);
        };

        // Populate the context.
        const context = {
            setPolicy,
            setSubmit,
            setReset,
            create,
        };

        // Return the factory context.
        return context;
    }

    /**
     * Instantiate a policy handler.
     * @param {Map<'submit'|'reset', FormNavigationPolicy>} map
     */
    constructor(map = new Map()) {
        /**
         * @type {Map<'submit'|'reset', FormNavigationPolicy>} Map of policies.
         */
        this.policies = new Map();
        Object.seal(this);

        // Initialize with default submit policy, if none provided.
        this.policies.set(
            'submit',
            map?.get('submit') ??
                FormNavigationPolicyHandler.getDefaultPolicy('submit')
        );

        // Initialize with default reset policy, if none provided.
        this.policies.set(
            'reset',
            map?.get('reset') ??
                FormNavigationPolicyHandler.getDefaultPolicy('reset')
        );
    }

    get submit() {
        return this.getPolicy('submit');
    }

    get reset() {
        return this.getPolicy('reset');
    }

    /**
     * Get the navigation policy.
     * @param {'submit' | 'reset'} type
     */
    getPolicy(type) {
        return this.policies.get(type);
    }

    /**
     * Set the navigation policy.
     * @param {'submit' | 'reset'} type
     * @param {FormNavigationPolicy} policy
     */
    setPolicy(type, policy) {
        return this.policies.set(type, policy);
    }

    /**
     * Clear policies of a given type and replace with the default.
     * @param {'submit'|'reset'} type
     */
    clearPolicy(type) {
        this.policies.delete(type);
        this.policies.set(
            type,
            FormNavigationPolicyHandler.getDefaultPolicy(type)
        );
    }
}
