// Composable used for managing the CSV Uploader data.

// <!-- API -->
// ts-ignore
import { computed, ref } from 'vue';

// <!-- COMPOSABLES -->
import { ComposableModule } from '@/hooks/useComposable';
import { UploadFormConfig } from '~CSVUploader/hooks/form/useUploadForm';

// <!-- TYPES -->
import { UploadRecord } from '@/store/types/uploader/state';
/** @typedef {import('@/models/v1/locations/Location').LocationResource} LocationResource */

// <!-- CLASS -->
/**
 * @class
 * Form data controller.
 * @extends {ComposableModule<UploadFormConfig>}
 */
export class UploadFormData extends ComposableModule {
    /**
     * Create a workflow for a specific form.
     * @param {UploadFormConfig} config
     */
    constructor(config) {
        super('data', config);
        // ts-ignore
        const { store } = config;

        // /** @type {V.ComputedRef<FormStep>} */
        // this.currentStep = computed(() => {
        //     this.mountKey.value;
        //     return store.workflow.value.currentStep;
        // });

        // /** @type {V.ComputedRef<UploadConfiguration>} */
        // this.options = computed(() => {
        //     this.mountKey.value;
        //     return store.api.state.uploader.config;
        // });

        // /** @type {V.ComputedRef<Map<String, UploadRecord>>} */
        // this.records = computed(() => {
        //     this.mountKey.value;
        //     return store.data.value.records;
        // });

        // /** @type {V.ComputedRef<UploadRecord[]>} */
        // this.selectedRecords = computed(() => {
        //     this.mountKey.value;
        //     return store.data.value.all.all();
        // });

        // /** @type {V.ComputedRef<UploadRecord[]>} */
        // this.whereMarkedForRemoval = computed(() => {
        //     this.mountKey.value;
        //     return store.data.value.whereMarkedForRemoval.all();
        // });

        // /** @type {V.ComputedRef<File[]>} */
        // this.selectedFiles = computed(() => {
        //     this.mountKey.value;
        //     return store.data.value.selectedFiles.all();
        // });

        // /** @type {V.ComputedRef<LocationResource[]>} */
        // this.selectedLocations = computed(() => {
        //     this.mountKey.value;
        //     return store.data.value.selectedLocations.all();
        // });

        // /** @type {V.ComputedRef<Boolean>} */
        // this.isFull = computed(() => {
        //     this.mountKey.value;
        //     return this.records.value.size >= this.options.value.fileCountLimit;
        // });

        // /** @type {V.ComputedRef<Map<Number, LocationResource>>} */
        // this.accountLocations = computed(() => {
        //     this.mountKey.value;
        //     return store.locations.value.index;
        // });

        // /** @type {V.ComputedRef<Map<String, LocationResource>>} */
        // this.suggestedLocations = computed(() => {
        //     this.mountKey.value;
        //     const suggestions = store.data.value.all.map((r) => {
        //         /** @type {[ String, LocationResource ]} */
        //         const entry = [r.filename, r.suggestedLocation];
        //         return entry;
        //     });
        //     return new Map(suggestions);
        // });

        // /** @type {V.Ref<Number>} */
        // this.mountKey = ref(1);

        // /** Update mount key. */
        // const onUpdate = () => {
        //     this.mountKey.value += 1;
        //     if (this.mountKey.value > 100000000) {
        //         this.mountKey.value = 1;
        //     }
        // };

        // /** Update mount key on these mutations/actions. */
        // const watched = {
        //     mutations: [
        //         `uploader/data/changeRecordFlag`,
        //         `uploader/data/clearRecords`,
        //         `uploader/data/insertRecord`,
        //         `uploader/data/selectFile`,
        //     ],
        //     actions: [
        //         `uploader/data/dropRecord`,
        //         `uploader/data/dropRecordsWhereMarkedForRemoval`,
        //         `uploader/data/dropRecordLocation`,
        //         `uploader/data/dropRecordMappingProfile`,
        //         `uploader/data/dropRecordDatasetBatch`,
        //         `uploader/data/selectRecordLocation`,
        //     ],
        // };

        // // Update mount key on watched mutations.
        // store.api.store.subscribe((mutation, state) => {
        //     if (watched.mutations.includes(mutation.type)) {
        //         onUpdate();
        //     }
        // });

        // // Update mount key on watched actions.
        // store.api.store.subscribeAction((action, state) => {
        //     if (watched.actions.includes(action.type)) {
        //         onUpdate();
        //     }
        // });
    }

    /**
     * Provides state actions.
     * @param {V.SetupContext<any, any>} context
     */
    useDataAction(context) {
        const { store } = this.config;
        const { emit } = context;
        const { dispatch } = store.api;
        // ts-ignore
        const $data = this;
        return {
            get input() {
                return {
                    /**
                     * @param {Object} payload
                     * @param {File[]} payload.files
                     * @param {any[]} payload.removed
                     */
                    files: async ({ files, removed }) => {
                        if (removed && removed.length) {
                            console.warn(
                                `Removed ${removed.length} duplicates...`
                            );
                        }

                        // Prepare results.
                        const fulfilled = [];
                        const rejected = [];

                        // Select files sequentially since
                        // we don't want to add more than
                        // the capacity limit.
                        for (const file of files) {
                            try {
                                const result = await dispatch(
                                    `uploader/data/selectRecordFile`,
                                    { file }
                                );
                                fulfilled.push(result);
                            } catch (err) {
                                rejected.push(err);
                            }
                        }

                        if (fulfilled.length) {
                            console.log(
                                `${fulfilled.length} record(s) added successfully.`,
                                fulfilled
                            );
                        }

                        if (rejected.length) {
                            console.error(
                                `${rejected.length} record(s) rejected.`,
                                rejected
                            );
                            rejected.forEach((result) => {
                                if (result.status === 'rejected') {
                                    emit('alert', result?.reason);
                                }
                            });
                        }
                    },
                    /**
                     * Select single record's location.
                     * @param {String} filename
                     * @param {LocationResource} location
                     * @returns {Promise<UploadRecord>}
                     */
                    location: async (filename, location) => {
                        return await dispatch(
                            `uploader/data/selectRecordLocation`,
                            { filename, location }
                        );
                    },
                    /**
                     * Select single record's transient mapping profile.
                     * @param {String} filename
                     * @param {import('@/models/v1/mappings/MappingProfile').MappingProfileResource} profile
                     * @returns {Promise<UploadRecord>}
                     */
                    // ts-ignore
                    profile: async (filename, profile) => {
                        return await dispatch(
                            `uploader/data/selectRecordMappingProfile`,
                            { filename, profile }
                        );
                    },
                };
            },
            get drop() {
                return {
                    /**
                     * Clear all records.
                     */
                    all: async () => {
                        await dispatch(`uploader/data/clearRecords`);
                    },
                    /**
                     * Clear the selected property.
                     * @param {String} filename
                     */
                    location: async (filename) => {
                        return await dispatch(
                            `uploader/data/dropRecordLocation`,
                            {
                                filename,
                            }
                        );
                    },
                    /**
                     * Clear the selected property.
                     * @param {String} filename
                     */
                    profile: async (filename) => {
                        return await dispatch(
                            `uploader/data/dropRecordMappingProfile`,
                            { filename }
                        );
                    },
                    /**
                     * Clear the selected property.
                     * @param {String} filename
                     */
                    batch: async (filename) => {
                        return await dispatch(
                            `uploader/data/dropRecordDatasetBatch`,
                            {
                                filename,
                            }
                        );
                    },
                    /**
                     * Drop record by filename.
                     * @param {String} filename
                     */
                    byFilename: async (filename) => {
                        await dispatch(`uploader/data/dropRecord`, {
                            filename,
                        });
                    },
                    /**
                     * Drop all marked records.
                     */
                    whereMarkedForRemoval: async () => {
                        await dispatch(
                            `uploader/data/dropRecordsWhereMarkedForRemoval`
                        );
                    },
                };
            },
        };
    }
}

/**
 * Initialize form navigator.
 * @param {UploadFormConfig} form
 */
export const useUploadData = (form) => {
    return new UploadFormData(form);
};
