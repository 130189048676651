<template>
    <div>
        <div
            class="flex flex-row-reverse justify-between items-end px-2 py-2 space-x-3 sm:px-3"
        >
            <ModalButton
                :key="mountKey(`submit-button`)"
                theme="primary"
                id="submit-step"
                name="submit"
                type="submit"
                class="flex-shrink-0"
                :title="submit.label.value"
                v-if="submit.show.value"
                :disabled="!submit.show.value || !submit.enable.value"
                @click.prevent="submit.onClick"
            >
                {{ submit.label.value }}
            </ModalButton>
            <ModalButton
                :key="mountKey(`reset-button`)"
                theme="white"
                id="reset-step"
                name="reset"
                type="reset"
                class="flex-shrink-0"
                :title="reset.label.value"
                v-if="reset.show.value"
                :disabled="!reset.show.value || !reset.enable.value"
                @click.prevent="reset.onClick"
            >
                {{ reset.label.value }}
            </ModalButton>
        </div>
    </div>
</template>

<script>
    // <!-- API -->
    import { defineComponent, computed, toRefs, onUnmounted } from 'vue';

    // <!-- COMPONENTS -->
    import ModalButton from '@/components/modals/ModalButton.vue';

    // <!-- COMPOSABLES -->
    import { UploadFormConfig } from '~CSVUploader/hooks/form/useUploadForm';
    import { useMountKey } from '@/hooks/reactivity/useMountKey';

    // <!-- TYPES -->

    import {
        FormNavigationPolicy,
        FormNavigationPolicyHandler,
    } from '@/hooks/useNavigationPolicies';
    import { useStore } from 'vuex';

    // <!-- DEFINITION -->
    export default defineComponent({
        name: 'UploaderNavigation',
        components: { ModalButton },
        props: {
            form: {
                /** @type {V.PropType<UploadFormConfig>} */
                type: Object,
                required: true,
            },
        },
        setup(props, context) {
            const { form } = toRefs(props);

            /**
             * Get the component mount key hack
             * used to force updates to the component state
             * when subscribed to certain actions and mutations.
             */
            const {
                mountKey,
                getNamespacedMountKey,
                subscribeWatchedActions,
                subscribeWatchedMutations,
                resetMountKey,
            } = useMountKey();

            /**
             * Get current navigation policy handler.
             * @type {V.ComputedRef<FormNavigationPolicyHandler>}
             */
            const policies = computed(() => {
                const _ = mountKey.value;
                return form.value.workflow.currentNavigationPolicyHandler.value;
            });

            /**
             * Form navigation policy getter.
             * @param {FormNavigationPolicy} policy
             */
            const getFromPolicy = (policy) => {
                return {
                    ref: policy,
                    label: policy.label,
                    show: policy.isVisible,
                    enable: policy.isEnabled,
                    onClick: policy.onClick.bind(policy),
                };
            };

            /**
             * Get computed property for submit.
             */
            const submit = computed(() => {
                const _ = mountKey.value;
                return getFromPolicy(policies.value.submit);
            });

            /**
             * Get computed property for reset.
             */
            const reset = computed(() => {
                const _ = mountKey.value;
                return getFromPolicy(policies.value.reset);
            });

            // LIFECYCLE

            const store = useStore();
            subscribeWatchedActions(store, [`*`]);
            subscribeWatchedMutations(store, [`*`]);

            onUnmounted(() => {
                resetMountKey();
            });

            return {
                mountKey: getNamespacedMountKey,
                policies,
                submit,
                reset,
            };
        },
    });
</script>
