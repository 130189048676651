<template>
    <div class="flex main-section">
        <div class="flex-grow overflow-y-auto print:overflow-y-visible pb-6">
            <div class="border-b border-gray-200 outer-border">
                <div class="max-w-7xl mx-auto px-5 pt-6 sm:pt-0">
                    <SubNav :subTabs="tabs" />
                    <PageTitle></PageTitle>
                </div>
            </div>
            <slot name="mainContent">
                <!-- FALLBACK CONTENT -->
                CSV Uploader Content
            </slot>
        </div>
    </div>
</template>

<script>
    // <!-- API -->
    import { defineComponent, onBeforeMount } from 'vue';
    import { useRouter } from 'vue-router';

    // <!-- COMPONENTS -->
    import SubNav from '@components/SubNav.vue';
    import PageTitle from '@components/PageTitle.vue';

    // <!-- COMPOSABLES -->
    import { useTabDefs } from '@/hooks/useTabDefs';

    // <!-- DEFINITION -->
    export default defineComponent({
        name: 'UploaderContainer',
        components: {
            SubNav,
            PageTitle,
        },
        props: {
            /** Collection of {@link SN.Tab} items used to define the tabs. */
            tabs: {
                /** @type {V.PropType<SN.Tab[]>} */
                type: Array,
                default: () => [
                    { name: 'Upload Data', to: '/upload', current: false },
                ],
            },
        },
        setup(props) {
            // Get the router.
            const router = useRouter();
            // Create the tab functionality.
            const { updateCurrentTabByRoute } = useTabDefs(props, router);
            // Regsiter lifecycle.
            onBeforeMount(updateCurrentTabByRoute);
        },
    });
</script>

<style scoped>
    .main-section {
        min-height: calc(100vh - 4rem);
    }

    @media print {
        .main-section {
            height: auto;
        }
    }
</style>
