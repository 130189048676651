<template>
    <Panel class="mt-4">
        <LoadingWrapper :isLoading="true">
            <div v-show="false">Fallback content.</div>
        </LoadingWrapper>
    </Panel>
</template>

<script>
    // <!-- API -->
    import { defineComponent } from 'vue';

    // <!-- PANEL -->
    import Panel from '@/components/Panel.vue';

    // <!-- COMPONENTS -->
    import LoadingWrapper from '@/components/LoadingWrapper.vue';

    // <!-- DEFINITION -->
    export default defineComponent({
        name: 'LoadingFormStep',
        components: {
            Panel,
            LoadingWrapper,
        },
    });
</script>
