// Composable used for managing the CSV Uploader steps.

// <!-- API -->
import { computed, ref } from 'vue';

// <!-- COMPOSABLES -->
import { ComposableModule } from '@/hooks/useComposable';
import { UploadFormConfig } from '~CSVUploader/hooks/form/useUploadForm';

// <!-- COMPONENTS -->
// import GenericFormStep from '~CSVUploader/components/steps/GenericFormStep.vue';

// <!-- TYPES -->
import { FormStep, FormStepDefinition } from '@/hooks/useFormStep';
import {
    FormNavigationPolicy,
    FormNavigationPolicyHandler,
} from '@/hooks/useNavigationPolicies';

// <!-- CLASS -->

/**
 * @class
 * Form navigation controller.
 * @extends {ComposableModule<UploadFormConfig>}
 */
export class UploadFormWorkflow extends ComposableModule {
    /**
     * Create a workflow for a specific form.
     * @param {UploadFormConfig} config
     */
    constructor(config) {
        super('workflow', config);
        const { store } = config;

        /** @type {Map<String, String>} */
        this.StepComponents = new Map();

        /** @type {FormStepDefinition[]} */
        this.StepDefinitions = [];

        /** @type {V.ComputedRef<Map<String, FormStep>>} */
        this.steps = computed(() => store.workflow.value.steps);

        /** @type {V.ComputedRef<FormStep[]>} */
        this.sequence = computed(() => store.workflow.value.sequence);

        /** @type {V.ComputedRef<FormStep>} */
        this.currentStep = computed(() => store.workflow.value.currentStep);

        /** @type {V.ComputedRef<Number>} */
        this.currentStepIndex = computed(
            () => store.workflow.value.currentStepIndex
        );

        this.hasPreviousStep = computed(() => {
            return store.workflow.value.hasStep(
                store.workflow.value.currentStepIndex - 1
            );
        });

        this.hasNextStep = computed(() => {
            return store.workflow.value.hasStep(
                store.workflow.value.currentStepIndex + 1
            );
        });

        /** @type {V.ComputedRef<{ inactive: String, active: String, done: String }>} */
        this.colors = computed(() => ({
            inactive: 'gray-200',
            active: 'primary-800',
            done: 'primary-800',
        }));

        /** @type {V.ComputedRef<String>} */
        this.header = computed(() => {
            return this.currentStep.value?.label ?? '';
        });

        /** @type {V.ComputedRef<String>} */
        this.message = computed(() => {
            return this.currentStep.value?.description ?? '';
        });

        /**
         * Workflow is ready to start.
         * @type {V.ComputedRef<Boolean>}
         */
        this.isWorkflowReady = computed(() => {
            const workflow = store.workflow.value;
            return workflow.isIdle && !workflow.isDone && !workflow.isRunning;
        });

        /**
         * Workflow is considered busy while data or cache indices are fetching.
         */
        this.isWorkflowBusy = computed(() => {
            const data = store.data.value;
            const conditions = [
                // cache.locations.value.has.status('fetching'),
                // cache.profiles.value.has.status('fetching'),
                data.isSuggestingLocations,
                data.isUploading,
                data.isSuggestingMappingProfiles,
                data.isRefreshingMappingProfilePreview,
                data.isCreatingMappingProfiles,
                data.isApplyingMappingProfiles,
                data.isIngesting,
            ];
            // Consider busy if any condition is true.
            return conditions.some((value) => value === true);
        });

        /** @type {{ submit: FormNavigationPolicy, reset: FormNavigationPolicy }} */
        this.DefaultNavigationPolicies = {
            submit: FormNavigationPolicy.prepare()
                .label.exact('Next | Test')
                .visible.whenTrue(
                    () =>
                        store.workflow.value.currentStepIndex <
                        store.workflow.value.count - 1
                )
                .type.asSubmit()
                .create(),
            reset: FormNavigationPolicy.prepare()
                .label.exact('Back')
                .visible.whenTrue(
                    () => store.workflow.value.currentStepIndex > 0
                )
                .type.asReset()
                .create(),
        };

        /** @type {FormNavigationPolicyHandler} Default handler used when no step is present. */
        this.DefaultNavigationPolicyHandler =
            FormNavigationPolicyHandler.prepare()
                .setPolicy(this.DefaultNavigationPolicies.submit)
                .setPolicy(this.DefaultNavigationPolicies.reset)
                .create();

        /** @type {V.Ref<FormNavigationPolicyHandler>} */
        this.policyHandler = ref(null);

        /** @type {V.ComputedRef<FormNavigationPolicyHandler>} Current navigation policy handler. */
        this.currentNavigationPolicyHandler = computed(() => {
            return (
                this?.policyHandler?.value ??
                this.DefaultNavigationPolicyHandler
            );
        });
    }

    /**
     * Initialize the workflow.
     */
    async initialize() {
        const { store, constants } = this.config;
        const { StepDetails, StepIDs } = constants;

        // INFO
        const StepInfo = new Map(StepDetails);

        // ASYNC COMPONENT IDs
        /** @type {Map<String, String>} */
        this.StepComponents = new Map([
            [StepIDs[0], 'FileSelectorStep'],
            [StepIDs[1], 'LocationSelectorStep'],
            [StepIDs[2], 'ProfileSelectorStep'],
            [StepIDs[3], 'UploadSummaryStep'],
        ]);

        // FORM STEP DEFINITIONs
        this.StepDefinitions = [];
        for (const id of StepIDs) {
            const def = StepInfo.get(id).using.id(id);
            this.StepDefinitions.push(def);
        }

        // WORKFLOW CREATION
        if (store.workflow.value.count === 0) {
            console.groupCollapsed(`[workflow::create]`);
            const sequence = store.createSequenceFromDefs(this.StepDefinitions);
            await store.createWorkflowFromSequence(sequence);
            console.groupEnd();
        }
    }

    /**
     * Start the workflow.
     */
    async start() {
        const { store } = this.config;
        // WORKFLOW START
        if (store.workflow.value.count > 0 && store.workflow.value.isIdle) {
            console.groupCollapsed(`[workflow::start]`);
            await store.startWorkflow();
            console.groupEnd();
        }
    }

    /**
     * Stop the workflow.
     */
    async stop() {
        const { store } = this.config;
        // WORKFLOW STOP
        if (!store.workflow.value.isIdle) {
            console.groupCollapsed(`[workflow::stop]`);
            await store.stopWorkflow();
            console.groupEnd();
        }
    }

    /**
     * Restart the workflow.
     */
    async restart() {
        const { store } = this.config;
        // WORKFLOW STOP
        if (store.workflow.value.count > 0 && !store.workflow.value.isIdle) {
            console.groupCollapsed(`[workflow::restart]`);
            await store.restartWorkflow();
            console.groupEnd();
        }
    }
}

/**
 * Initialize form navigator.
 * @param {UploadFormConfig} form
 */
export const useUploadWorkflow = (form) => {
    return new UploadFormWorkflow(form);
};
