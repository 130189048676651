<template>
    <div class="py-2">
        <div
            class="pb-4"
            aria-hidden="true"
        >
            <div class="bg-gray-200 rounded-full overflow-hidden">
                <div
                    class="h-2 rounded-full transition-colors duration-500 ease-out"
                    :class="[
                        isBusy ? 'animate-pulse bg-primary-400' : barColor,
                    ]"
                    :style="{ width: barWidth }"
                ></div>
            </div>
            <div
                class="hidden sm:grid text-sm font-medium text-gray-600 mt-6"
                :class="gridColumns"
            >
                <div
                    v-for="(step, index) in steps"
                    :key="index"
                    :class="getClasses(index)"
                >
                    {{ getLabel(index) }}
                </div>
            </div>
        </div>
        <h4 class="sr-only">Status</h4>
        <p
            v-if="message?.length"
            class="text-sm font-medium text-gray-900"
        >
            {{ message }}
        </p>
    </div>
</template>

<script>
    // <!-- API -->
    import { defineComponent, toRefs } from 'vue';

    // <!-- COMPOSABLES -->
    import { useFormProgressBar } from '@/hooks/useFormProgressBar';

    // <!-- TYPES -->
    /** @typedef {import('@/hooks/useFormStep').FormStep} FormStep */

    // <!-- DEFINITION -->
    export default defineComponent({
        name: 'FormProgressBar',
        props: {
            steps: {
                /** @type {V.PropType<FormStep[]>} */
                type: Array,
                default: () => [],
            },
            index: {
                type: Number,
                default: 0,
            },
            disabled: {
                type: Boolean,
                default: false,
            },
            colors: {
                /** @type {V.PropType<{ inactive: String, active: String, done: String }>} */
                type: Object,
                default: () => ({
                    inactive: 'gray-200',
                    active: 'primary-800',
                    done: 'secondary-500',
                }),
            },
            isBusy: {
                type: Boolean,
                default: false,
            },
        },
        setup(props) {
            // Allow props to be destructured.
            const { steps, index, disabled, colors } = toRefs(props);
            const { computed, getters, validators } = useFormProgressBar(
                { steps, index, disabled },
                {
                    minWidth: 10,
                    ...colors.value,
                }
            );

            const getLabel = (index) => {
                const label = getters.getLabel(index);
                const id = index + 1;
                return `${id}. ${label}`;
            };

            // Expose properties.
            return {
                message: '',
                ...computed,
                ...getters,
                ...validators,
                getLabel,
            };
        },
    });
</script>
