<template>
    <TransitionRoot
        as="template"
        :show="hasSteps && !!currentStep"
        enter="transition-opacity duration-75"
        enter-from="opacity-0"
        enter-to="opacity-100"
        leave="transition-opacity duration-150"
        leave-from="opacity-100"
        leave-to="opacity-0"
    >
        <TransitionChild as="div">
            <component
                v-if="hasSteps"
                id="form-content"
                :form="form"
                :is="currentStepComponent"
                @alert="onStepAlert"
                @set:policies="onPolicyUpdate"
            />
        </TransitionChild>
    </TransitionRoot>
</template>

<script>
    // <!-- API -->
    import { defineComponent, toRefs, computed } from 'vue';

    // <!-- COMPONENTS -->
    import { TransitionChild, TransitionRoot } from '@headlessui/vue';

    // <!-- COMPOSABLES -->
    import { UploadFormConfig } from '~CSVUploader/hooks/form/useUploadForm';
    import { useStepComponent } from '~CSVUploader/hooks/workflow/useStepComponent';

    // <!-- TYPES -->
    import { UploadStore } from '~CSVUploader/hooks/store/useUploadStore';
    import { FormStep } from '@/hooks/useFormStep';
    import { FormNavigationPolicyHandler } from '@/hooks/useNavigationPolicies';

    // <!-- DEFINITION -->
    export default defineComponent({
        name: 'UploaderContent',
        components: {
            TransitionRoot,
            TransitionChild,
            // ASYNC COMPONENT(S)
            GenericFormStep: useStepComponent('GenericFormStep'),
            FileSelectorStep: useStepComponent('FileSelectorStep'),
            LocationSelectorStep: useStepComponent('LocationSelectorStep'),
            ProfileSelectorStep: useStepComponent('ProfileSelectorStep'),
            UploadSummaryStep: useStepComponent('UploadSummaryStep'),
        },
        props: {
            form: {
                /** @type {V.PropType<UploadFormConfig<any, any>>} */
                type: Object,
                required: true,
            },
        },
        setup(props, context) {
            // PROPS
            const { form } = toRefs(props);

            /** @type {UploadStore} */
            const store = form.value.store;

            // METHODS
            const getStepComponent = form.value.methods.getStepComponent;

            // PROPERTIES

            /** @type {V.ComputedRef<Boolean>} */
            const hasSteps = computed(() => store.workflow.value.count > 0);

            /** @type {V.ComputedRef<FormStep>} */
            const currentStep = computed(
                () => store.workflow.value.currentStep
            );

            /** @type {V.ComputedRef<String>} */
            const currentStepComponent = computed(() => {
                const currentStepID = currentStep.value.id;
                return getStepComponent(currentStepID);
            });

            /**
             * Event capture.
             */
            const onPolicyUpdate = (event) => {
                console.dir(event);
                const handler = FormNavigationPolicyHandler.prepare()
                    .setPolicy(event.submit)
                    .setPolicy(event.reset)
                    .create();
                form.value.workflow.policyHandler.value = handler;
            };

            // EXPOSE
            return {
                hasSteps,
                currentStep,
                currentStepComponent,
                onStepAlert: form.value.handlers.onStepAlert,
                onPolicyUpdate,
            };
        },
    });
</script>
