// TODO - Enhance with global types.
export const Bases = Object.freeze({
    BYTE: 1,
    KILOBYTE: 10 ** 3,
    MEGABYTE: 10 ** 6,
    GIGABYTE: 10 ** 9,
    TERABYTE: 10 ** 12,
});

// TODO - Enhance with global types.
export const Factors = Object.freeze({
    BYTE: 1 / Bases.BYTE,
    KILOBYTE: 1 / Bases.KILOBYTE,
    MEGABYTE: 1 / Bases.MEGABYTE,
    GIGABYTE: 1 / Bases.GIGABYTE,
    TERABYTE: 1 / Bases.TERABYTE,
});

/**
 *
 * @param {Number} size File size in an arbitrary base.
 * @param {Number} base Magnitude of the base unit, in relation to 1 byte.
 * @param {Number} factor Magnitude of the conversion factor
 */
export const FormatFileSize = (
    size,
    base = Bases.BYTE,
    factor = Factors.MEGABYTE,
    digits = 0
) => {
    const bytes = size * base;
    const conversion = bytes * factor;
    let unit = 'Unknown Unit';
    switch (factor) {
        case Factors.BYTE:
            unit = 'bytes';
            break;
        case Factors.KILOBYTE:
            unit = 'KB';
            break;
        case Factors.MEGABYTE:
            unit = 'MB';
            break;
        case Factors.GIGABYTE:
            unit = 'GB';
            break;
        case Factors.TERABYTE:
            unit = 'TB';
            break;
    }
    return `${conversion.toFixed(digits)} ${unit}`;
};
