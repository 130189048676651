// <!-- API -->
import { ref } from 'vue';

// <!-- TYPES -->
import { Store } from 'vuex';

/**
 * @deprecated Create a mount key.
 */
export const useMountKey = () => {
    /** @type {V.Ref<Number>} */
    const mountKey = ref(1);

    /** @type {V.Ref<Set<'mutations' | 'actions'>>} */
    const subscribed = ref(new Set());

    /**
     * Get the namespaced element id.
     * @param {String} namespace
     */
    const getNamespacedMountKey = (namespace) =>
        `${mountKey.value}-${namespace}`;

    /**
     * Reset the mount key.
     */
    const resetMountKey = () => {
        mountKey.value = 1;
    };

    /**
     * Update the mount key.
     */
    const updateMountKey = () => {
        mountKey.value += 1;
        if (mountKey.value > 1000000000000) {
            resetMountKey();
        }
    };

    /**
     * Update the subscribed status.
     * @param {'mutations' | 'actions'} status
     */
    const updateSubscribed = (status) => {
        const keys = [...subscribed.value, status];
        subscribed.value = new Set(keys);
    };

    /**
     * Subscribe to store mutations.
     * @param {Store} store
     * @param {String[]} types
     */
    const subscribeWatchedMutations = (store, types) => {
        if (!subscribed.value.has('mutations')) {
            store.subscribe(({ type }, payload) => {
                if (types.includes('*') || types.includes(type)) {
                    updateMountKey();
                }
            });
            updateSubscribed('mutations');
        }
    };

    /**
     * Subscribe to store mutations.
     * @param {Store} store
     * @param {String[]} types
     */
    const subscribeWatchedActions = (store, types) => {
        if (!subscribed.value.has('actions')) {
            store.subscribeAction(({ type }, payload) => {
                if (types.includes('*') || types.includes(type)) {
                    updateMountKey();
                }
            });
            updateSubscribed('actions');
        }
    };

    return {
        mountKey,
        subscribed,
        resetMountKey,
        updateMountKey,
        getNamespacedMountKey,
        subscribeWatchedMutations,
        subscribeWatchedActions,
    };
};

export default useMountKey;
