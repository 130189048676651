// <!-- API -->
import { useECNBStore } from '@/hooks/store/useECNBStore';

// <!-- TYPES -->
import { Store } from 'vuex';
import { ECNBStore, ECNBState } from '@/store/types/ECNBStore';
import { FormStep, FormStepDefinition } from '@/hooks/useFormStep';

/**
 * Get the computed property, getters, and actions.
 * @param {Store<ECNBState>} [store] Vuex store reference.
 */
export const useUploadStore = (store = null) => {
    // Wrap the provided store or create a new one.
    const $ = useECNBStore(store);
    const cache = new UploadStore($.api.store);
    return cache;
};

/**
 * @class
 * ECNBStore wrapper.
 */
export class UploadStore extends ECNBStore {
    /**
     * Create specialized {@link ECNBStore} wrapper.
     * @param {Store} store Vuex store reference.
     */
    constructor(store) {
        super(store);
        /**
         * Get cached index.
         */
        this.locations = this.api.create.computed.cache.locations;
        /**
         * Get cached index.
         */
        this.profiles = this.api.create.computed.cache.profiles;
        /**
         * Get the module.
         */
        this.config = this.api.create.computed.uploader.config;
        /**
         * Get the module.
         */
        this.data = this.api.create.computed.uploader.data;
        /**
         * Get the module.
         */
        this.errors = this.api.create.computed.uploader.errors;
        /**
         * Get the module.
         */
        this.workflow = this.api.create.computed.uploader.workflow;
    }

    get hasCurrentStep() {
        return this.api.store.state.uploader.workflow.currentStep != null;
    }

    get hasPreviousStep() {
        return (
            this.hasCurrentStep &&
            this.api.store.state.uploader.workflow.previousStep != null
        );
    }

    get hasNextStep() {
        return (
            this.hasCurrentStep &&
            this.api.store.state.uploader.workflow.nextStep != null
        );
    }

    /**
     * Create ordered form step sequence from ordered array of form step definitions.
     * @param {FormStepDefinition[]} definitions
     * @returns {FormStep[]}
     */
    createSequenceFromDefs(definitions) {
        return definitions.map((d) => d.create());
    }

    /**
     * Create and begin workflow from provided sequence.
     * @param {FormStep[]} sequence
     */
    async createWorkflowFromSequence(sequence) {
        const { commit, dispatch } = this.api;
        commit(`uploader/workflow/setSequence`, sequence);
        await dispatch(`uploader/workflow/forceStepIndex`, -1);
    }

    /**
     * Start the workflow.
     */
    async startWorkflow() {
        const { dispatch } = this.api;
        await dispatch(`uploader/workflow/startWorkflow`);
        await dispatch(`uploader/data/resetUploadForm`);
    }

    /**
     * Stop the workflow.
     */
    async stopWorkflow() {
        const { dispatch } = this.api;
        await dispatch(`uploader/data/resetUploadForm`);
        await dispatch(`uploader/workflow/forceStepIndex`, -1);
    }

    /**
     * Restart the workflow.
     */
    async restartWorkflow() {
        const { dispatch } = this.api;
        await dispatch(`uploader/data/resetUploadForm`);
        await this.goToFirstStep(true);
    }

    /**
     * Go to first step.
     * @param {Boolean} allowTransition
     */
    async goToFirstStep(allowTransition = true) {
        const { dispatch } = this.api;
        await dispatch(`uploader/workflow/goToFirst`, { allowTransition });
    }

    /**
     * Go to last step.
     * @param {Boolean} allowTransition
     */
    async goToFinalStep(allowTransition = true) {
        const { dispatch } = this.api;
        await dispatch(`uploader/workflow/goToLast`, { allowTransition });
    }

    /**
     * Go to previous step.
     * @param {Boolean} allowTransition
     */
    async goToPreviousStep(allowTransition = true) {
        const { dispatch } = this.api;
        await dispatch(`uploader/workflow/goToPrevious`, { allowTransition });
    }

    /**
     * Go to next step.
     * @param {Boolean} allowTransition
     */
    async goToNextStep(allowTransition = true) {
        const { dispatch } = this.api;
        await dispatch(`uploader/workflow/goToNext`, { allowTransition });
    }

    /**
     * Attempt to upload the dataset batches.
     */
    async uploadDatasets() {
        const { dispatch } = this.api;

        // Upload records.
        const whereUploaded = await dispatch(`uploader/data/uploadDatasets`);
        console.log(`Uploaded ${whereUploaded?.length} record(s).`);

        // Go to next step if all batches are uploaded.
        const records = this.api.state.uploader.data.records;
        const whereBatchCreated = [...records.values()].filter(
            (r) => r.isDatasetBatchUploaded
        );
        await this.goToNextStep(whereBatchCreated.length === records.size);
    }

    /**
     * Attempt to create the mapping profiles.
     */
    async createMappingProfiles() {
        const { dispatch } = this.api;

        // Upload records.
        await dispatch(`uploader/data/createRecordMappingProfiles`);

        // Apply profiles.
        await this.applyMappingProfiles();
    }

    /**
     * Attempt to apply the mapping profiles.
     */
    async applyMappingProfiles() {
        const { dispatch } = this.api;

        // Upload records.
        await dispatch(`uploader/data/applyRecordMappingProfiles`);

        // Ingest datasets.
        await this.ingestDatasets();
    }

    /**
     * Attempt to ingest the dataset batches.
     */
    async ingestDatasets() {
        const { dispatch } = this.api;

        // Trigger the ingestion of records.
        const whereIngested = await dispatch(`uploader/data/ingestDatasets`);
        console.log(`Ingested ${whereIngested?.length} record(s).`);

        // Go to the next step.
        await this.goToNextStep(true);
    }
}

// DEFAULT
export default useUploadStore;
