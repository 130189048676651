<template>
    <ConfirmRestartModal
        :title="confirmDelete.title"
        :confirmationText="confirmDelete.confirmationText"
        :open="confirmDelete.isOpen"
        :cancel="confirmDelete.cancelLabel"
        :confirm="confirmDelete.confirmLabel"
        @submit="confirmDelete.onDelete"
        @cancel="confirmDelete.onCancel"
        @close="confirmDelete.onCancel"
    />
    <UploaderContainer>
        <template #mainContent>
            <PageWrapper>
                <PageIntro>
                    <PageDescription>
                        Upload new dataset for an existing Location.
                    </PageDescription>
                </PageIntro>
                <LoadingWrapper :isLoading="isFormLoading || !isFormReady">
                    <div class="grid grid-cols-1 space-x-0 space-y-2">
                        <UploaderHeader :form="form" />
                        <UploaderContent :form="form" />
                        <UploaderNavigation :form="form" />
                    </div>
                </LoadingWrapper>
            </PageWrapper>
        </template>
    </UploaderContainer>
</template>

<script>
    // <!-- API -->
    import {
        defineComponent,
        computed,
        onActivated,
        onDeactivated,
        onMounted,
        onUnmounted,
    } from 'vue';

    // <!-- COMPONENTS -->
    import LoadingWrapper from '@/components/LoadingWrapper.vue';
    import PageWrapper from '@components/PageWrapper.vue';
    import PageIntro from '@components/PageIntro.vue';
    import PageDescription from '@components/PageDescription.vue';
    import ConfirmRestartModal from '~CSVUploader/components/modals/ConfirmRestartModal.vue';
    import UploaderContainer from '~CSVUploader/components/wrappers/UploaderContainer.vue';
    import UploaderHeader from '~CSVUploader/components/wrappers/UploaderHeader.vue';
    import UploaderContent from '~CSVUploader/components/wrappers/UploaderContent.vue';
    import UploaderNavigation from '~CSVUploader/components/wrappers/UploaderNavigation.vue';

    // <!-- COMPOSABLES -->
    import {
        useUploadForm,
        UploadFormConfig,
        UploadFormProps,
    } from '~CSVUploader/hooks/form/useUploadForm';

    // <!-- TYPES -->
    /**  */ /** EmitsOptions} EmitsOptions */
    /** @template {V.EmitsOptions} [E=any]  */ /** SetupContext<E>} SetupContext<E> */

    // <!-- DEFINITION -->
    export default defineComponent({
        name: 'Uploader',
        components: {
            LoadingWrapper,
            ConfirmRestartModal,
            PageWrapper,
            PageIntro,
            PageDescription,
            UploaderContainer,
            UploaderHeader,
            UploaderContent,
            UploaderNavigation,
        },
        setup(_, context) {
            // COMPOSE
            /**
             * Interface for the CSV Upload form.
             * @type {UploadFormConfig<UploadFormProps, V.EmitsOptions>}
             *
             * This composable creates and initializes the
             * {@link UploadFormConfig} instance, representing
             * the CSV Upload form data and API.
             *
             * Use this hook to ensure the appropriate lifecycle methods
             * are called when using this form.
             */
            const form = useUploadForm(new UploadFormProps({}), context);

            // STATE
            const { confirmDeleteModal } = form.state;

            // EXTRACT
            const { isFormReady, isFormLoading } = form.properties;

            // METHODS
            const { onCancelWorkflow, onResumeWorkflow } = form.handlers;

            // MODALS
            const confirmDelete = computed(() => ({
                get title() {
                    return confirmDeleteModal.value.title;
                },
                get confirmationText() {
                    return confirmDeleteModal.value.prompt;
                },
                get isOpen() {
                    return confirmDeleteModal.value.isOpen === true;
                },
                get cancelLabel() {
                    return confirmDeleteModal.value.cancel;
                },
                get confirmLabel() {
                    return confirmDeleteModal.value.confirm;
                },
                onDelete: onCancelWorkflow,
                onCancel: onResumeWorkflow,
            }));

            const getRecord = (index) => {
                const array = [
                    ...form.store.api.state.uploader.data.records.values(),
                ];
                return index >= 0 && index < array.length ? array[index] : null;
            };

            // LIFECYLE
            onActivated(() => {
                form.methods.activate();
            });

            onDeactivated(() => {
                form.methods.deactivate();
            });

            onMounted(() => {
                form.methods.activate();
            });

            onUnmounted(() => {
                // form.workflow.stop();
            });

            // EXPOSE
            return {
                form,
                isFormReady,
                isFormLoading,
                confirmDelete,

                getRecord,
            };
        },
    });
</script>
