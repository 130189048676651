<template>
    <div>
        <FormSectionHeader
            :title="title"
            :description="description"
        />
        <FormProgressBar
            :steps="steps"
            :index="index"
            :colors="colors"
            :isBusy="isBusy"
        />
    </div>
</template>

<script>
    // <!-- API -->
    import { defineComponent, toRefs, computed } from 'vue';

    // <!-- COMPONENTS -->
    import FormSectionHeader from '@/components/forms/partials/FormSectionHeader.vue';
    import FormProgressBar from '@/components/FormProgressBar.vue';

    // <!-- COMPOSABLES -->
    import { UploadFormConfig } from '~CSVUploader/hooks/form/useUploadForm';

    // <!-- TYPES -->

    // <!-- DEFINITION -->
    export default defineComponent({
        name: 'UploaderHeader',
        components: {
            FormSectionHeader,
            FormProgressBar,
        },
        props: {
            form: {
                /** @type {V.PropType<UploadFormConfig>} */
                type: Object,
                required: true,
            },
        },
        setup(props, context) {
            // PROPS
            /** @type {{ form: V.Ref<UploadFormConfig>}} */
            const { form } = toRefs(props);

            // STORE
            const { workflow } = form.value;

            // PROPERTIES
            const colors = workflow.colors;
            const steps = workflow.sequence;
            const currentStep = workflow.currentStep;
            const index = workflow.currentStepIndex;
            const title = workflow.header;
            const description = workflow.message;
            const isBusy = workflow.isWorkflowBusy;

            // EXPOSE
            return {
                onStepAlert: form.value.handlers.onStepAlert,
                steps,
                currentStep,
                title,
                description,
                index,
                colors,
                isBusy,
            };
        },
    });
</script>
