// <!-- API -->
import { ref, computed } from 'vue';

// <!-- TYPES -->
// ts-ignore

import { FormStep } from '@/hooks/useFormStep';

/**
 * Get reactive function and component logic for the progress bar.
 *
 * @param {Object} state State from using context.
 * @param {V.Ref<FormStep[]>} state.steps Steps array reference.
 * @param {V.Ref<Number>} state.index Index reference.
 * @param {V.Ref<Boolean>} state.disabled Flag for if the component is disabled.
 * @param {Object} settings Settings that can change and are not associated with state.
 * @param {Number} settings.minWidth Minimum width to use when progress is zero.
 * @param {String} settings.inactive Color to use during relevant state.
 * @param {String} settings.active Color to use during relevant state.
 * @param {String} settings.done Color to use during relevant state.
 */
export const useFormProgressBar = (
    { steps, index, disabled },
    { minWidth, inactive, active, done }
) => {
    // PROGRESS
    // Safe to destructure.
    const progress = {
        total: computed(() => steps.value?.length ?? Infinity),
        interval: computed(() => (steps.value?.length ? index.value + 1 : 0)),
        ratio: computed(() => {
            if (progress.total.value === Infinity) {
                return 1;
            }
            return (progress.interval.value / progress.total.value) * 100;
        }),
    };

    // COLORS
    // Safe to destructure.
    const colors = {
        /** @type {V.Ref<String>} */
        inactive: ref(inactive ?? 'gray-600'),
        /** @type {V.Ref<String>} */
        active: ref(active ?? 'primary-800'),
        /** @type {V.Ref<String>} */
        done: ref(done ?? 'secondary-700'),
    };

    // VALIDATORS
    // Safe to destructure.
    const validators = {
        isValid: (i) => steps.value.length && i >= 0 && i < steps.value.length,
        isActive: (i) => validators.isValid(i) && i == index.value,
        isInactive: (i) =>
            validators.isValid(i) && !disabled.value && i > index.value,
        isCompleted: (i) => validators.isValid(i) && i <= index.value,
        isDone: computed(() => validators.isCompleted(steps.value.length - 1)),
        isDisabled: computed(() => disabled.value == true),
    };

    // COMPUTED PROPERTIES
    const properties = {
        currentStep: computed(() => {
            return steps.value[index.value];
        }),
        /** Get the number of grid columns. */
        gridColumns: computed(() => {
            return steps.value?.length ? `grid-cols-${steps.value.length}` : '';
        }),
        barColor: computed(() => {
            const asTailwindCSS = (color) => `bg-${color.value}`;
            if (validators.isDisabled.value) {
                return asTailwindCSS(colors.inactive);
            }
            if (validators.isDone.value) {
                return asTailwindCSS(colors.done);
            }
            return asTailwindCSS(colors.active);
        }),
        barWidth: computed(() => {
            let width = index.value ? progress.ratio.value : minWidth ?? 8;
            return `${width}%`;
        }),
    };

    // GETTERS.
    const getters = {
        getStep: (i) => steps.value[i],
        getLabel: (i) => getters.getStep(i).label,
        getDescription: (i) => getters.getStep(i).description,
        getTextColor: (i) => {
            const asTailwindCSS = (color) => `text-${color}`;
            if (validators.isValid(i) && !validators.isInactive(i)) {
                if (validators.isCompleted(i)) {
                    return asTailwindCSS(colors.done);
                }
                if (validators.isActive(i)) {
                    return asTailwindCSS(colors.active);
                }
            }
            return asTailwindCSS(colors.inactive);
        },
        getAlignment: (i) => {
            if (!steps.value.length) return '';
            switch (i) {
                case 0:
                    return '';
                case steps.value.length - 1:
                    return 'text-right';
                default:
                    return 'text-center';
            }
        },
        getClasses: (i) => [getters.getAlignment(i), getters.getTextColor(i)],
    };

    return {
        progress,
        colors,
        validators,
        computed: properties,
        getters,
    };
};

export default {
    useFormProgressBar,
};
