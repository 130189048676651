<template>
    <Panel class="mt-4">
        <template #header>{{ content?.header }}</template>
        <template #default>
            <div class="text-center">
                {{ content?.default }}
            </div>
        </template>
        <template #footer>
            <div class="text-center">
                {{ content?.footer }}
            </div>
        </template>
    </Panel>
</template>

<script>
    // <!-- API -->
    import { defineComponent } from 'vue';

    // <!-- PANEL -->
    import Panel from '@/components/Panel.vue';
    import { Emoji } from '@/utils/emoji';

    // <!-- DEFINITION -->
    export default defineComponent({
        name: 'MissingFormStep',
        components: {
            Panel,
        },
        setup() {
            const content = {
                header: `${Emoji.cross} Error!`,
                default:
                    'Uh oh! Something went wrong while loading the form. Try refreshing the page.',
                footer: 'Please consult the site maintainers.',
            };
            return {
                content,
            };
        },
    });
</script>
